.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.content-wrap {
  width: 100%;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.start-button, .submit-button {
  background-image: url(assets/ui/button_next.png);
  width: 18dvh;
  aspect-ratio: 2;
  position: absolute;
  bottom: 3dvh;
  left: 0;
  right: 0;
  margin: auto;
}

.setup-top {
  height: 14dvh;
  background-image: url(assets/bgr/KH_top_bgr.png);
  background-size: cover;
  background-position: bottom;
  display: flex;
}
.setup-logo {
  width: 100%;
  margin-top: 4%;
  height: 54%;
  background-size: contain;
  background-position: center;
}
.setup-logo.en {
  background-image: url(assets/bgr/logoEN.png);
}
.setup-logo.es {
  background-image: url(assets/bgr/logoES.png);
}
.setup-logo.cs {
  background-image: url(assets/bgr/logoCS.png);
}
.setup-logo.de {
  background-image: url(assets/bgr/logoDE.png);
}
.setup-logo.fr {
  background-image: url(assets/bgr/logoFR.png);
}
.setup-logo.ja {
  background-image: url(assets/bgr/logoJA.png);
}
.setup-logo.it {
  background-image: url(assets/bgr/logoIT.png);
}
.setup-logo.pl {
  background-image: url(assets/bgr/logoPL.png);
}
.setup-logo.ko {
  background-image: url(assets/bgr/logoCS.png);
}
.carousel-card {
  position: absolute;
  right: 1dvh;
  width: 7dvh;
}

.guild-setup-icon {
  width: 10dvh;
  aspect-ratio: 1.95;
  border-radius: 0.5dvh;
  background-size: contain;
  background-position: right;
}

.guild-setup-icon-scribes {
  background-image: url("assets/ui/guild_scribe.png");
}
.guild-setup-icon-builders {
  background-image: url("assets/ui/guild_builder.png");
}
.guild-setup-icon-grocers {
  background-image: url("assets/ui/guild_grocer.png");
}
.guild-setup-icon-innkeepers {
  background-image: url("assets/ui/guild_innkeeper.png");
}
.guild-setup-icon-metallurgists {
  background-image: url("assets/ui/guild_metalurg.png");
}
.guild-setup-icon-miners {
  background-image: url("assets/ui/guild_miners.png");
}

.resource-info button.plus-button {
  background-image: url(assets/ui/people_plus.png);
  width: 20%;
  aspect-ratio: 1.3;
  position: absolute;
  left: 45%;
  top: 12%;
}

button.plus-button.pulsing {
  animation: plus-pulse 1s alternate infinite;
}

@keyframes plus-pulse {
  from {
    transform: scale(1.5)
  }
  to {
    transform: scale(1.7)
  }

}