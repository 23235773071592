.settings {
  color: white;
  text-align: center;
  width: 100%;
  max-height: 77dvh;
  overflow: scroll;
  mask-image: linear-gradient(0deg,
  rgba(0,0,0,0) 0%,
  rgba(0,0,0,1) 5%,
  rgba(0,0,0,1) 98%,
  rgba(0,0,0,0) 100%);
  padding-bottom: 3dvh;
}


.settings-header {
  font-size: 5dvh;
}

.settings .header {
  font-size: 4dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2dvh;
  margin-bottom: 1dvh;
}

.settings .arrow {
  width: 15dvh;
  aspect-ratio: 10;
  background-size: contain;
  opacity: 0.5;
}

.economy-wrap {
  display: flex;
  justify-content: space-evenly;
}

.economy-select {
  background-image: url(assets/ui/button_grey.png);
  height: 4dvh;
  aspect-ratio: 4.5;
  color: white;
  font-size: 2dvh;
  font-weight: 900;
}
.economy-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  font-size: 2dvh;
  margin-top: 1.5dvh;
}
.economy-select.active {
  background-image: url(assets/ui/button_green.png);
}
.standard-info .info-image {
  background-image: url(assets/ui/standard_example.png);
}

.open-info .info-image {
  background-image: url(assets/ui/open_example.png);
  aspect-ratio: 1.6;
}

.econ-disclaimer {
  margin: 0 2dvh;
  font-size: 2dvh;
  margin-top: 1.5dvh;
}

.info-image {
  width: 20dvh;
  aspect-ratio: 1.29;
  background-size: contain;
}

.info-image:not(.active) {
  filter: grayscale(0.8);
}

.arrow-right {
  background-image: url(assets/bgr/arrow_right.png);
}
.arrow-left {
  background-image: url(assets/bgr/arrow_left.png);
}


button.sound-button {
  width: 8dvh;
  aspect-ratio: 1;
  margin: 0 3dvh;
  transition: filter .3s, transform .3s;
  filter: none;
}
button.sound-off {
  background-image: url(assets/ui/nosound.png);
}
button.sound-on {
  background-image: url(assets/ui/sound.png);
}

button.sound-button:not(.active) {
  filter: grayscale(1);
  transform: scale(0.8);
}



.lang-cs {
  background-image: url(assets/flags/cs.png);
}
.lang-en {
  background-image: url(assets/flags/en.png);
}
.lang-es {
  background-image: url(assets/flags/es.png);
}
.lang-fr {
  background-image: url(assets/flags/fr.png);
}
.lang-ja {
  background-image: url(assets/flags/ja.png);
}
.lang-de {
  background-image: url(assets/flags/de.png);
}
.lang-ko {
  background-image: url(assets/flags/ko.png);
}

.lang-button {
  width: 10dvh;
  aspect-ratio: 1.5;
  opacity: 0.7;
  filter: grayscale(0.5);
  transition: opacity .2s transform .5s;
}


.lang-button.active {
  filter: drop-shadow(0 0 2px white);
  opacity: 1;

}