
.carousel-wrap .carousel-number-button {
  scroll-snap-align: center;
}

.carousel-wrap {
  overflow: scroll;
  height: 42dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;
  mask-image: linear-gradient(180deg,
  rgba(0,0,0,0) 0%,
  rgba(0,0,0,0.3) 30%,
  rgba(0,0,0,1) 40%,
  rgba(0,0,0,1) 60%,
  rgba(0,0,0,0.3) 70%,
  rgba(0,0,0,0) 100%);
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.carousel-wrap::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
.carousel-mid {
  background-image: url(assets/icons/highlight_star.png);
  width: 8dvh;
  right: -0.4dvh;
  aspect-ratio: 1;
  background-size: contain;
  transform: translateY(-50%);
  position: absolute;
  top: 20.7dvh;
  pointer-events: none;
}

.safari .carousel-mid {
  top: 21.2dvh;
}

.carousel-wrap-wrap {
  position: relative;
  scroll-behavior: smooth;
}

.carousel-number-button:nth-child(1) {
  margin-top: 18dvh;
}

.carousel-number-button:last-child {
  margin-bottom: 18dvh;
}