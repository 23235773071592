.tutorial-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  mix-blend-mode: multiply;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.tutorial-seethrough {
  background: white;
  border-radius: 2dvh;
  position: absolute;
  transition: left .6s, top .6s, width .6s, height .6s;
}

.tutorial-continue, .tutorial-skip {
  background: url(assets/ui/button_green.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 30dvh;
  aspect-ratio: 4.7;
  z-index: 15;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 12dvh;
  font-size: 3dvh;
  color: white;
  white-space: pre-line;
}

.tutorial-skip {
  bottom: 2dvh;
}
.lang-wrap-ja .tutorial-skip {
  bottom: 2dvh;
  font-size: 1.8dvh;
}

.tutorial-text {
  font-size: 4dvh;
  color: white;
  position: absolute;
  z-index: 15;
  text-align: center;
  pointer-events: none;
}

.lang-wrap-ja .tutorial-text {
  font-size: 3dvh;
  text-align: left;
}